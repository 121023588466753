import React from 'react';
import './Footer.css';
import logo from '../assets/logo-white.png'; // Importiere das Logo

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>Kontakt</h4>
          <p>GALL GU GmbH<br/>Elzweg 4<br/>4900 Langenthal</p>
          <p>E-Mail: info@gall-gu.ch</p>
        </div>
        {/* <div className="footer-section">
          <h4>Folge uns</h4>
          <p>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
          </p>
          <p>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
          </p>
        </div> */}
        <div className="footer-section">
          <img src={logo} alt="GALL GU GmbH Logo" className="footer-logo" /> {/* Füge das Logo ein */}
          <p>&copy; 2024 GALL GU GmbH</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
