import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import Partner from './pages/Partner';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import './App.css';
import { initScrollHandler } from './utils/scrollHandler'; // Importiere den Scroll-Handler

function App() {
  useEffect(() => {
    initScrollHandler(); // Initialisiere den Scroll-Handler
  }, []);

  return (
    <Router>
      <div className="app-container">
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ueber-uns" element={<About />} />
            <Route path="/dienstleistungen" element={<Services />} />
            {/* <Route path="/galerie" element={<Gallery />} /> */}
            {/* <Route path="/partner" element={<Partner />} /> */}
            <Route path="/kontakt" element={<Contact />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
