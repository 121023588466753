import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';
import headerImage from '../assets/about-us-header.webp'; // Beispielhaftes Header-Bild

const About = () => {
  return (
    <div className="about-us">
      <header className="header" style={{ backgroundImage: `url(${headerImage})` }}>
      </header>
      <section className="about-section">
        <div className="container-fluid d-flex flex-wrap justify-content-center">
          <div className=" col-md-10 introduction">
            <h2>Über uns</h2>
            <p>Bei der GALL GU GmbH stehen Qualität, Präzision und Kundenzufriedenheit an erster Stelle. Mit einem engagierten Team bieten wir erstklassige handwerkliche Dienstleistungen in den Bereichen Malerei, Gipsarbeiten, Aussenisolationen und Renovierungen. Wir arbeiten eng mit Privatpersonen, Architekten und Bauherren zusammen, um ihre Projekte nach höchsten Standards und zu ihrer vollen Zufriedenheit zu realisieren. Unser Ziel ist es, nachhaltige Lösungen zu bieten, die sowohl funktional als auch ästhetisch ansprechend sind.</p>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6 heading-background about-us-content-1-background">
            <h2 className="section-heading">Unsere Geschichte / Philosophie</h2>
          </div>
          <div className="col-12  col-md-6 text-box">
            <p>
            Seit unserer Gründung im Jahr 2011 sind wir bestrebt, handwerkliche Exzellenz mit einer kundenorientierten Herangehensweise zu verbinden. Unsere Philosophie ist es, jeden Auftrag individuell zu betrachten und auf die Bedürfnisse unserer Kunden einzugehen. Wir glauben daran, dass nachhaltige und langlebige Ergebnisse nur durch den Einsatz von hochwertigen Materialien und handwerklicher Präzision erreicht werden können.
            </p>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6 text-box">
            <p>
            Unser Team besteht aus erfahrenen Handwerkern, die mit Leidenschaft und Fachwissen an jedes Projekt herangehen. Durch regelmässige Schulungen und den Einsatz modernster Techniken stellen wir sicher, dass unsere Arbeit stets auf dem neuesten Stand der Technik ist. Ob es sich um kleinere Renovierungen oder grössere Bauprojekte handelt – wir arbeiten immer Hand in Hand, um das beste Ergebnis zu erzielen.
            </p>
          </div>
          <div className="col-12  col-md-6 heading-background about-us-content-2-background">
            <h2 className="section-heading">Unser Team</h2>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6 heading-background about-us-content-3-background">
            <h2 className="section-heading">Unsere Werte</h2>
          </div>
          <div className="col-12  col-md-6 text-box">
            <p>
            Unsere Arbeit basiert auf Vertrauen, Transparenz und Qualität. Wir legen grossen Wert auf offene Kommunikation mit unseren Kunden, um sicherzustellen, dass jedes Detail den Wünschen und Anforderungen entspricht. Wir setzen auf nachhaltige Bauweisen und umweltfreundliche Materialien, um sowohl die Umwelt als auch unsere Kunden zu schützen.
            </p>
          </div>
        </div>
      </section>
      {/* <section className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6 text-box">
            <p>"Die Arbeiten wurden professionell und termingerecht ausgeführt. Sehr empfehlenswert!" - Kunde A</p>
            <p>"Top Qualität und hervorragender Service. Wir sind sehr zufrieden." - Kunde B</p>
          </div>
          <div className="col-12  col-md-6 heading-background about-us-content-4-background">
            <h2 className="section-heading">Kundenstimmen</h2>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6 heading-background about-us-content-5-background">
            <h2 className="section-heading">Bildergalerie</h2>
          </div>
          <div className="col-12  col-md-6 text-box">
            <p>
            Seit unserer Gründung im Jahr 2011 sind wir bestrebt, handwerkliche Exzellenz mit einer kundenorientierten Herangehensweise zu verbinden. Unsere Philosophie ist es, jeden Auftrag individuell zu betrachten und auf die Bedürfnisse unserer Kunden einzugehen. Wir glauben daran, dass nachhaltige und langlebige Ergebnisse nur durch den Einsatz von hochwertigen Materialien und handwerklicher Präzision erreicht werden können.
            </p>
          </div>
        </div>
      </section> */}
      <section className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6 text-box">
            <p>
            Möchten Sie mehr über uns erfahren oder uns Ihr nächstes Projekt anvertrauen? Kontaktieren Sie uns <Link to="/kontakt">hier</Link> und lassen Sie uns gemeinsam grossartige Ergebnisse erzielen!
            </p>
          </div>
          <div className="col-12  col-md-6 heading-background about-us-content-5-background">
            <h2 className="section-heading">Kontaktieren Sie uns</h2>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
