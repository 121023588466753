import React from 'react';
import './Services.css';
import { Link } from 'react-router-dom';
import headerImage from '../assets/services-header.webp';

const Services = () => {
  return (
    <div className="services">
      <header className="header" style={{ backgroundImage: `url(${headerImage})`, backgroundPosition: '50% 80%' }}>
      </header>
      <section className="services-section">
        <div className="container-fluid d-flex flex-wrap justify-content-center">
          <div className=" col-md-10 introduction">
            <h2>Dienstleistungen</h2>
            <p>Wir bieten Ihnen ein breites Spektrum an professionellen Dienstleistungen im Bereich Malerei, Gipsarbeiten, Aussenisolationen und Renovierungen. Unser erfahrenes Team sorgt für hochwertige Ergebnisse, die sowohl funktional als auch ästhetisch ansprechend sind.</p>
          </div>
        </div>
      </section>
      <section id='malerei' className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6 heading-background services-content-1-background">
            <h2 className="section-heading">Malerei</h2>
          </div>
          <div className="col-12  col-md-6 text-box">
            <p>
            Von der kreativen Wandgestaltung bis hin zu hochwertigen Anstrichen – wir bieten Ihnen professionelle Malerarbeiten für Innen- und Aussenbereiche. Unsere Farben sind langlebig und umweltfreundlich, um Ihnen nachhaltige Ergebnisse zu garantieren.
            </p>
          </div>
        </div>
      </section>
      <section id='gipsarbeiten' className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6 text-box">
            <p>
            Unsere Gipserarbeiten zeichnen sich durch Präzision und Perfektion aus. Ob für Neubauten oder Renovierungen, wir sorgen für glatte und saubere Oberflächen in Innenräumen.
            </p>
          </div>
          <div className="col-12  col-md-6 heading-background services-content-2-background">
            <h2 className="section-heading">Gipsarbeiten</h2>
          </div>
        </div>
      </section>
      <section id='aussenisolationen' className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6 heading-background services-content-3-background">
            <h2 className="section-heading">Aussenisolationen</h2>
          </div>
          <div className="col-12  col-md-6 text-box">
            <p>
            Energieeffizienz beginnt an der Fassade. Mit unseren hochwertigen Aussenisolationen schützen Sie Ihr Gebäude vor Witterungseinflüssen und sparen langfristig Energiekosten.
            </p>
          </div>
        </div>
      </section>
      <section id='renovierungen' className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6 text-box">
            <p>Wir verwandeln alte Räume in moderne und funktionale Lebensbereiche. Von der Planung bis zur Umsetzung – unsere Renovierungsarbeiten bieten massgeschneiderte Lösungen für Ihre Bedürfnisse.</p>
          </div>
          <div className="col-12  col-md-6 heading-background services-content-4-background">
            <h2 className="section-heading">Renovierungen</h2>
          </div>
        </div>
      </section>
      <section id='trockenbau' className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6 heading-background services-content-6-background">
            <h2 className="section-heading">Trockenbau</h2>
          </div>
          <div className="col-12  col-md-6 text-box">
            <p>Mit unserer langjährigen Erfahrung im Trockenbau bieten wir massgeschneiderte Lösungen für den Innenausbau. Ob Trennwände, Deckenverkleidungen oder Komplettlösungen für Umbauten – wir arbeiten präzise und effizient, um Ihren individuellen Bedürfnissen gerecht zu werden. Dank moderner Materialien und Techniken garantieren wir schnelle und saubere Ergebnisse, die höchsten Qualitätsstandards entsprechen.</p>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container-fluid d-flex flex-wrap">
          <div className="col-12  col-md-6 text-box">
            <p>
            Haben Sie ein Projekt im Kopf? Kontaktieren Sie uns <Link to="/kontakt">hier</Link> noch heute, und lassen Sie uns Ihre Vision gemeinsam umsetzen.
            </p>
          </div>
          <div className="col-12  col-md-6 heading-background services-content-5-background">
            <h2 className="section-heading">Kontaktieren Sie uns</h2>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
