import React, { useState } from 'react';
import './Contact.css';
import headerImage from '../assets/contact-header.webp';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    company: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  });
  const [captchaVerified, setCaptchaVerified] = useState(false); // Zustand für reCAPTCHA
  const [captchaToken, setCaptchaToken] = useState(null); // Zustand für das reCAPTCHA-Token

  // Funktion, die aufgerufen wird, wenn reCAPTCHA erfolgreich abgeschlossen ist
  const onCaptchaChange = (value) => {
    setCaptchaToken(value); // Speichere das reCAPTCHA-Token
    setCaptchaVerified(true); // Bestätige, dass reCAPTCHA erfolgreich war
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaVerified || !captchaToken) {
      alert('Bitte bestätigen Sie, dass Sie kein Roboter sind.');
      return;
    }

    // Hier sendet EmailJS die Formulardaten zusammen mit dem reCAPTCHA-Token
    emailjs.sendForm('service_k0hw4xr', 'template_fww0d4a', e.target, 'hjKtLvRpBW4a7WfZn', {
      'g-recaptcha-response': captchaToken,  // Füge das reCAPTCHA-Token hinzu
    })
    .then((result) => {
      console.log(result.text);
      alert('Ihre Nachricht wurde gesendet. Vielen Dank!');
    }, (error) => {
      console.log(error.text);
    });

    // Leere das Formular nach dem Senden
    setFormData({
      firstname: '',
      lastname: '',
      email: '',
      subject: '',
      message: ''
    });

    // Zurücksetzen des Captchas
    setCaptchaVerified(false);
    setCaptchaToken(null);
  };

  return (
    <div className="contact">
      <header className="header" style={{ backgroundImage: `url(${headerImage})` }}>
      </header>
      <section id="contact" className="contact-section">
        <div className='contact-form-container'>
          <h2>Kontaktieren Sie uns</h2>

          <form onSubmit={handleSubmit} className='contact-form'>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="firstname">Vorname <span className="required">*</span></label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                className="form-control"
                value={formData.firstname}
                onChange={handleInputChange}
                placeholder="Ihr Vorname"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastname">Nachname <span className="required">*</span></label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                className="form-control"
                value={formData.lastname}
                onChange={handleInputChange}
                placeholder="Ihr Nachname"
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="company">Firma</label>
              <input
                type="text"
                id="company"
                name="company"
                className="form-control"
                value={formData.company}
                onChange={handleInputChange}
                placeholder="Firma"
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Telefon <span className="required">*</span></label>
              <input
                type="text"
                id="phone"
                name="phone"
                className="form-control"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Ihre Telefon Nr."
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email">E-Mail <span className="required">*</span></label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Ihre E-Mail-Adresse"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Betreff <span className="required">*</span></label>
            <input
              type="text"
              id="subject"
              name="subject"
              className="form-control"
              value={formData.subject}
              onChange={handleInputChange}
              placeholder="Betreff"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Nachricht <span className="required">*</span></label>
            <textarea
              id="message"
              name="message"
              className="form-control"
              rows="5"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Ihre Nachricht"
              required
            ></textarea>
          </div>

          {/* Erklärung für Pflichtfelder */}
          <p className="required-note">
            <span className="required">*</span> Pflichtfelder
          </p>

            <div className="form-group">
              <ReCAPTCHA
                sitekey="6Lfv0T0qAAAAACJJYwgYhHKDZbxCvuLuqe8ecRGp"  // Replace with your Google reCAPTCHA site key
                onChange={onCaptchaChange}
              />
            </div>

            <button type="submit">Absenden</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Contact;
