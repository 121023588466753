export const initScrollHandler = () => {
  document.addEventListener('DOMContentLoaded', () => {
    let lastScrollTop = 0;
    const navbar = document.querySelector('.navbar');

    if (!navbar) {
      console.error('Navbar element not found');
      return;
    }

    window.addEventListener('scroll', function() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        // Scrolling down
        navbar.style.top = '-87px'; // Adjust the value based on your navbar height
      } else {
        // Scrolling up
        navbar.style.top = '0';
      }
      
      // Reset position when scrolled to top
      if (scrollTop === 0) {
        navbar.style.top = '0';
      }

      lastScrollTop = scrollTop;
    });
  });
};
